/* .project {
  width: auto;
  margin-left: 8vw;
  margin-right: 8vw;
} */

.description {
  margin-top: -0.5rem;
}

.link {
  /* width: ; */
  margin-left: 8vw;
  margin-right: 8vw;
}

.link a {
  color: #f5f5f5;
}
