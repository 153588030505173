.page {
  /* background-color: #0c0c0c;
    background-color: #1b1235; */

  /* padding: 40px; */
  /* height: 100vh; */
  /* width: 70vw; */
  /* margin: auto; */
  display: flex;
  flex-direction: column;
  align-items: left;
  height: 100vh;
  min-height: 700px;
  margin-bottom: 0rem;
  /* overflow: scroll; */
  scroll-snap-align: center;
}
