.modal {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 50px;
  z-index: 10000;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  font-style: Arial;
}

.modal p {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  color: black;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 10000;
}
