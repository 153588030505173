.contactMeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  height: 100%;
}

.text {
  align-self: center;
  width: 30vw;
}

@media (max-width: 600px) {
  .text {
    width: 50vw;
  }
}
