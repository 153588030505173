.container {
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  height: 100vh;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  scroll-behavior: smooth;
}
.container::-webkit-scrollbar {
  display: none;
}
.back {
  background-color: #2d0c5b;
  /* height: 100rem; */
  width: 60vw;
  margin-left: 20vw;
  /* display: flex; */
  /* flex-direction: column;
  align-items: center;
  align-self: center; */
  /* overflow-y: scroll;
  scroll-snap-type: y mandatory; */
}

@media (max-width: 600px) {
  .back {
    width: 100vw;
    margin-left: 0;
    z-index: 1000;
  }
}

h2 {
  /* height: 5rem; */
  width: auto;
  /* background-color: white; */
  /* border-style: solid;
  border-color: white; */
  /* border-width: 2rem; */
  /* background-color: var(--bg-color); */
  font-family: Consolas, monospace;
  color: #f5f5f5;
  margin-left: 2vw;
  margin-right: 2vw;
}

h3 {
  width: auto;
  margin-left: 5vw;
  margin-right: 5vw;
  font-family: Consolas, monospace;
  color: #f5f5f5;
}

p {
  font-family: Consolas, monospace;
  color: #f5f5f5;
  width: auto;
  margin-left: 8vw;
  margin-right: 8vw;
}

/* a {
  font-family: Consolas, monospace;
  color: #f5f5f5;
  width: auto;
  margin-left: 8vw;
} */

h4 {
  font-family: Consolas, monospace;
  color: #f5f5f5;
}

/* ::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #2d0c5b;
}

::-webkit-scrollbar-thumb {
  background: #5f43b2;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #6d55b6;
} */
