.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  /* width: 100%;
  height: 100%; */
  /* background-color: #f5f5f5; */
  font-family: Consolas, monospace;
  /* margin-top: 2rem; */
  /* margin-bottom: 2rem; */
}

.container input,
textarea {
  all: unset;
  max-width: 600px;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 20px;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  font-family: Consolas, monospace;
  width: 30vw;
  word-wrap: break-word;
  min-width: 13rem;
}

.container textarea {
  height: 10rem;
}

.container button {
  all: unset;
  font-family: Consolas, monospace;
  max-width: 600px;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 20px;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  color: #2d0c5b;
  width: 30vw;
  text-align: center;
  font-size: 17px;
  min-width: 13rem;
}

.container button:hover {
  opacity: 0.9;
  cursor: pointer;
}

@media (max-width: 600px) {
  .container textarea {
    height: 2rem;
  }
}
