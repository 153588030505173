.aboutMeContainer {
  display: flex;
  /* when doing at media remove align-content to fix */
  flex-direction: column;
  margin-left: 5vw;
  margin-right: 5vw;
  /* flex-basis: 0;
  flex-grow: 1; */
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-items: center;
  margin-top: 0rem;
  margin-bottom: 1rem;
  height: 100%;
}

.skillsContainer {
  /* flex-basis: 0;
  flex-grow: 1; */
  height: 25vw;
  width: 25vw;
  margin-top: 3rem;
  max-width: 40vh;
  max-height: 40vh;
}

.paragraph {
  margin-left: 0;
  margin-right: 2rem;
}

@media (max-width: 600px) {
  .aboutMeContainer {
    margin-top: -2rem;
  }
  .skillsContainer {
    margin-top: 0rem;
  }
  .paragraph {
    font-size: 14px;
  }
}
