.dot {
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.5s ease;
  background-color: var(--bg-color);
  border-style: solid;
  border-width: 3px;
  border-color: black;
  border-color: var(--brdr-color);
  margin: auto;
  /* margin-bottom: 100px; */
}

.dot:hover {
  transform: scale(1.3);
}

.line {
  width: 2px;
  height: 50px;
  margin: auto;
  background-color: #2d0c5b;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
}
