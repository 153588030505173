.socials {
  position: fixed;
  left: calc(10vw - 25px);
  top: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  width: 50px;
  z-index: 300;
}

.socials a {
  margin: auto;
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
  transition: all 0.5s ease;
}
.icons:hover {
  color: white;
}

.line {
  width: 2px;
  height: 50px;
  margin: auto;
  background-color: #2d0c5b;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

/* .social :global(.social-svg-icon) {
  transition: all 0.5s ease !important;
}

.social:hover :global(.social-svg-icon) {
  fill: #5f43b2 !important;
  transform: scale(1.07);
} */

.socials a:hover {
  transform: scale(1.2);
  cursor: pointer;
}

@media (max-width: 600px) {
  .socials {
    display: none;
  }
}
