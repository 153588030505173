.skillsContainer {
  display: inline-block;
  position: relative;
  width: 100%;
  padding-top: 100%;
}

.skillsContainer img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 80%;
  max-height: 80%;
  border-radius: 50%;
  transform: scaleX(-1);
}
/* width: 50%; /* or any other value */
/* padding-top: 50%;
background-color: red; /* just to show the element */

/* 
#container {
    display: inline-block;
    position: relative;
    width: 50%;
} */
/* .skillsContainer:after {
  content: "";
  display: block;
  margin-top: 75%;
} */
